/*
* FILE NAME: postObservationStyles.css
* AUTHOR: Mike Kelly
*/

/*
* Media queries that resize the relevant components as the width of the screen changes.
* If you want to chage the width of the components on screen these are the only styles you need to edit. 
*/
@media screen and (min-width: 2001px) {
    .observation-back-button {
        width: 40%;
        height: 60px;
    }
    .popup-frame {
        position: relative;
        width: 30vw;
        max-width: 30vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(35% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 2000px) {
    .observation-back-button {
        width: 50%;
        height: 60px;
    }
    .popup-frame {
        position: relative;
        width: 35vw;
        max-width: 35vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(32.5% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 1750px) {
    .observation-back-button {
        width: 60%;
        height: 60px;
    }

    .popup-frame {
        position: relative;
        width: 40vw;
        max-width: 40vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(30% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 1500px) {
    .observation-back-button {
        width: 70%;
        height: 60px;
    }

    .list-container {
        display: flex;
        width: 70%;
        flex-direction: column;
        justify-content: center;
    }

    .popup-frame {
        position: relative;
        width: 45vw;
        max-width: 46vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(27.5% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .observation-back-button {
        width: 80%;
        height: 60px;
    }

    .list-container {
        display: flex;
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }

    .popup-frame {
        position: relative;
        width: 55vw;
        max-width: 56vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(22.5% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .observation-back-button {
        width: 80%;
        height: 60px;
    }

    .list-container {
        display: flex;
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }

    .popup-frame {
        position: relative;
        width: 70vw;
        max-width: 71vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(15% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}

@media screen and (max-width: 900px) {
    .observation-back-button {
        width: 100%;
        height: 60px;
    }

    .list-container {
        display: flex;
        width: 98%;
        flex-direction: column;
        justify-content: center;
    }

    .popup-frame {
        position: relative;
        width: 90vw;
        max-width: 91vw;
        margin: 0 auto;
        height: auto;
        max-height: 88%;
        margin-top: calc(100vh - 88vh - 20px);
        background: #fff;
        border-radius: 5px;
        padding: 0px;
        border: 1px solid #999;
        overflow: auto;
    }

    .popup-close-icon {
        cursor: pointer;
        position: fixed;
        right: calc(5% - 10px);
        top: calc(100vh - 88vh - 30px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    }
}
/*End of media queries.*/

/* Add this to your existing CSS to center the content */

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    justify-content: center; /* Center the content vertically */
    width: 80%; /* Set width to 80% of the container or any fixed width you prefer */
    max-width: 500px; /* Optional: limit max width */
    height: 300px; /* Set a fixed height to make it rectangular */
    margin: 0 auto; /* Center horizontally */
    border: 3px solid #ddd; /* Adds a light border around the image */
    overflow: hidden; /* Prevent content overflow */
  }
  
/* .card-contianer {
    display: flex;
    width: 100%;
    max-width: 1000px;
    height: 20vh;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
} */

.card-photo-bg {
    display: flex;
  justify-content: center; /* Center the photo horizontally */
  align-items: center; /* Center the photo vertically */
  width: 100%;
  height: 100%; /* Adjust height of the photo section */
  background-color: #f5f5f5; /* Optional: background color */
  overflow: hidden; /* Crop image inside this area */
}

.card-photo {
    object-fit: cover; /* Ensure image fills the container and is cropped to fit */
  width: 100%;
  height: 100%; /* Image fills the entire container */
}

.card-button-bg{
    display: flex;
    justify-content: center; /* Keep buttons centered horizontally */
    align-items: center; /* Vertically center buttons if needed */
    gap: 10px;
    width: 50%; /* Set width to 50% */
    margin-top: 10px;
    padding: 10px;
    margin-left: auto;  /* This ensures the container itself is centered */
    margin-right: auto; /* This ensures the container itself is centered */
    flex-wrap: wrap; /* Allow wrapping of buttons if they exceed the width */
}

.card-button {
    width: 100%;
    min-width: 0;
    overflow: hidden;
    height: 20%;
    box-sizing: border-box;
    border: 1px solid;
}

.popup-blur {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.popup-header {
    margin-top: 10;
    margin-bottom: 10;
}

.popup-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.sight-list-container {
    display: flex;
    width: 98%;
    height: 90%;
    border-top: 1px solid #ccc;
    flex-direction: column;
    justify-content: center;
}

.sight-row {
    display: flex;
    width: 98%;
    height: 12vh;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1px;
}

.sight-row-picture {
    display: flex;
    width: 35%;
    height: 100%;
    background-color: #bbb;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    border-radius: 5px;
}

.sight-row-info-container {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    border-radius: 5px;
}

.sight-row-title {
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
}

.sight-row-radio {
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    
}