/*
* DESCRIPTION: This file handles the styling of pages and components.
*/

/* 
  Discription added by Kiernan Pol 10-23-2024
  Discription updated by  
*/

.map-container {
  display: flex;               /* Enable flexbox */
  justify-content: center;     /* Center the map horizontally */
  align-items: center;         /* Center the map vertically */
  width: 100%;                 /* Full width */
  height: calc(100vh - 100px); /* Dynamic height (full viewport height minus any header/footer) */
  padding: 20px;               /* Optional padding */
  box-sizing: border-box;      /* Include padding in width/height */
  overflow: hidden;            /* Prevent overflow */
}

.map {
  width: 100%;                 /* Map takes full width of the container */
  height: 100%;                /* Map takes full height of the container */
}

.title-bar {
  background-color: darkred;
  text-align: center;
  font-family: "Inter", serif;
  font-weight: normal;
  color: white;
  width: 100%;
  margin: 10;           /* Remove any margins to prevent overflow */
  position: relative;     /* Make it fixed to the top of the window */
  left: 0;             /* Align it to the left */
  z-index: 2;
}

.swiper-button-prev {
  background-image: url('../images/arrow_circle_left.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 6vh;
  height: 6vh;

  position: absolute;
  top: calc(50% - 3vh);
  left: 6%;
  z-index: 10;
}

.swiper-button-next {
  background-image: url('../images/arrow_circle_right.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 6vh;
  height: 6vh;

  position: absolute;
  top: calc(50% - 3vh);
  right: 6%;
  z-index: 10;
}

.swiper-button-disabled {
  opacity: 50%;
}

.mainSwiper {
  .swiper-slide {
    visibility: visible;
  }

  .swiper-slide-prev,
  .swiper-slide-active,
  .swiper-slide-next {
    visibility: visible;
  }
}

/*.homeSwiper {*/
/*  .swiper-slide {*/
/*    filter: brightness(50%);*/
/*  }*/
/*  .swiper-slide-active {*/
/*    filter: brightness(100%);*/
/*  }*/
/*}*/

.title-recent-posts {
  text-align: center;
  font-family: var(--system-font);
  color: rgb(0, 0, 0);
  width: 100vw;
}

.post-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  /*Responsive grid */
  gap: 3px; /* Space between grid items */
  grid-row-gap: 1px; /* Set the gap between rows */
  grid-column-gap: 1px; /* Set the gap between columns */
  padding: 0 80px;
  justify-content: center;
}

/* Force a 3x3 grid on desktop screens */
 @media (min-width: 1024px) {
  .post-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
    gap: 2px;
    grid-row-gap: 1px; /* Set the gap between rows */
    padding: 0 150px;
    max-width: 1200px;
    margin: 0 auto;
  }
}


.featured-post-tile { 
  width: 100%;
  max-width: 300px;
  margin: 1px auto;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 2px; /* Rounded corners */
}

.media-content {
  width: 100%;
  height: 200px; /* Ensures media has a consistent height */
  object-fit: cover; /* Ensures the image or video fits properly */
  display: block;
  border: 3px solid #ddd; /* Adds a light border around the image */
}

.media-container {
  width: 100%;
  height: 600px;
  /* height: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}

.media-container img {
  width: 100%; /* Ensures the image takes up the full container width */
  height: 100%; /* Ensures the image fills the container vertically */
  object-fit: cover; /* Crops the image to fill the container, maintaining aspect ratio */
}

.post-details {
  font-family: var(--system-font);
  background-color: #f8f8f8;
  /* border-top: 1px solid #ddd; */
  border: 3px solid #ddd; /* Adds a light border around the image */
  border-top: 0px;
  text-align: center;
}

.post-details h2 {
  margin: 0;
  font-size: 1.1em; /* Slightly smaller font size */
  color: #333;
}

.post-details p {
  margin-top: 0.5px; /* Space between title and date */
  margin-bottom: 4px;
  font-size: 0.8em; /* Smaller font size for the date */
  color: #666;
}

.post-details a {
  text-decoration: none;
  color: inherit;
}


.post-details2 {
  font-family: var(--system-font);
  background-color: #f8f8f8;
  border: 3px solid #ddd;
  /* border-top: 3px solid #ddd; Explicitly adds the top border */
  text-align: center;
  
  /* Flexbox properties */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  padding: 0; /* Ensure no padding so border wraps tightly around content */
  justify-content: flex-start; /* Aligns content from the top */
  position: relative; /* Allows absolute positioning for media if needed */
  height: auto;
  margin: 0;
}

.post-details2 h2, .post-details2 p {
  margin: 0; /* Removes any default spacing */
  padding: 0; /* Ensures no extra padding around text */
  text-align: center; /* Centers text within the element */
}

.post-details2 h2 {
  font-size: 1.1em;
  color: #333;
}

.post-details2 p {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 4px;
}

/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .post-details2 h2 {
      font-size: 0.8rem; /* Reduce font size for smaller screens */
  }

  .post-details2 p {
      font-size: 0.5rem; /* Reduce font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .post-details2 h2 {
      font-size: 0.5rem; /* Further reduce font size for very small screens */
  }

  .post-details2 p {
      font-size: 0.3rem; /* Further reduce font size for very small screens */
  }
}

.recent-post-loading-message {
  text-align: center;
  font-family: var(--system-font);
  padding: 20px; /* Add padding around the text */
  color: #666; /* Light gray color */
  font-size: 1.2em;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center; /* Center the text and spinner */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #666; /* Darker border on the left to create the spinning effect */
  border-radius: 50%; /* Make it a circle */
  width: 30px;
  height: 30px;
  margin-top: 10px; /* Space between the text and spinner */
  animation: spin 1s linear infinite; /* Spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  100% {
    transform: rotate(360deg); /* Rotate a full circle */
  }
}

video {
  display:block;
  width: 100%;
}

.carousel-arrow {
  position: absolute;
  top: 40%; /* Default for larger screens */
  z-index: 10;
  background: none;
  border: none;
  cursor: pointer;
  transition: top 0.3s ease; /* Smooth transition */
}

.left-arrow {
  left: 0%;
}

.right-arrow {
  right: 0%;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .carousel-arrow {
      top: auto; /* Reset vertical alignment */
      bottom: 10%; /* Position aligned with View Post button */
  }
}

.info-text {
  display: flex;
  margin-bottom: 30px;
  font-size: 1.2em; /* Default font size for larger screens */
}

.info-label {
  width: 15vw;
  color: #555555;
  margin-left: 2em;
}

.info-value {
  color: #000000;
}

@media (max-width: 768px) {
  .info-text {
    font-size: 0.8em; /* Smaller font size for mobile devices */
  }

  .info-label {
    margin-left: 0.8em;
  }
}
