.splide__arrow--prev {
    left: 0.5em;
}

.splide__arrow--next {
    right: 0.5em;
}

.splide__pagination {
    position: static;
}

.splide__pagination__page.is-active {
    background-color: darkred;
}