.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: darkred;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  align-self: stretch;
  position: sticky;
  top: 0;
  z-index: 2;
}

.site-title {
  font-family: var(--heading-font);
  font-size: 2rem;
  margin-left: 0.5em;
}

@media (max-width: 650px) {
  .site-title {
    display: none;
  }
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}