/* massupload.css created by Mai Nguyen, used to style the mass upload appearance*/
.mass-upload-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjusts based on screen size */
    gap: 10px; /* Small gap between each item */
    padding: 16px;
}

.mass-upload-centered-media {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

/* One column on small screens */
@media (max-width: 600px) {
    .mass-upload-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Two columns on medium screens */
  @media (min-width: 601px) and (max-width: 1023px) {
    .mass-upload-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Three columns on large screens */
  @media (min-width: 1024px) {
    .mass-upload-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
.mass-upload-item {
    width: 100%;
    max-width: 300px;
    margin: auto;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.mass-upload-media-content {
    width: 100%;
    height: 200px; /* Consistent height */
    object-fit: cover; /* Ensures the image or video fits properly */
    border: 2px solid #ddd; /* Border similar to homepage */
    display: block;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #d9534f; /* Red color for delete icon */
    margin-top: 8px; /* Space between media and delete button */
  }
  
  .delete-button:hover {
    color: #c9302c; /* Darker red on hover */
  }
