@import url("https://fonts.googleapis.com/css2?family=Inter&family=Jost&display=swap");

:root {
  --system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --heading-font: "Jost", var(--system-font);
  --body-font: "Inter", var(--system-font);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #252627;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

html, body, #root {
  min-height: 100vh;
}

.react-tabs__tab--selected {
  color: darkred !important;
}

.react-tabs__tab {
  font-weight: bold;
  color: #707070;
  border-color: #707070;
}
/*
Next 2 video-container css fix issue with videos being in portrait mode overlapping other videos.
*/
.video-container {
  position: relative;             
  width: 100%; 
  height: 0; 
  padding-bottom: 56.25%; 
  overflow: hidden; 
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
}