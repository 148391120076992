    
  table {
    border: 4px solid darkred;
    display: flexbox;
    
  }
    
  th {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flexbox;
  }
    
  td {
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flexbox;
  }